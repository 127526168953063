import React, { FC, useState } from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import classNames from 'classnames';
import useBodyRef from 'hooks/useBodyRef';
import { BODY_CLASS_NAME_MOBILE_MENU } from 'components/Header/constants';
import CarouselButtonGroup from './CarouselButtonGroup';
import CAROUSEL_CONSTANTS from './constants';
import { IProductCarousel, CarouselMasks } from './models';

import 'react-multi-carousel/lib/styles.css';
import './ProductCarousel.scss';

const carouselClassName = 'dt-carousel';
const containerClassName = `${carouselClassName}__container`;

const ProductCarousel: FC<IProductCarousel> = ({
  children,
  type,
  centerMode,
  draggable,
  partialVissible,
  infinite = true,
  limitWidth,
  visibleElements,
  isBtnGroup,
}) => {
  const itemCount = React.Children.count(children);
  const responsiveCarouselLayout: ResponsiveType = React.useMemo(
    () => CAROUSEL_CONSTANTS(itemCount)[type],
    [itemCount]
  );
  const containerClass = classNames(containerClassName, {
    [`${containerClassName}--${CarouselMasks.rightMask}`]: type === CarouselMasks.rightMask,
    [`${containerClassName}--${CarouselMasks.noMasks}`]: type === CarouselMasks.noMasks,
    [`${containerClassName}--${CarouselMasks.bothMasks}`]: type === CarouselMasks.bothMasks,
    [`${containerClassName}--${CarouselMasks.bothMasksAssistant}`]:
      type === CarouselMasks.bothMasksAssistant,
    [`${containerClassName}--${CarouselMasks.singleElement}`]: type === CarouselMasks.singleElement,
    [`${containerClassName}--visible-element`]: visibleElements,
    [`${containerClassName}--single-element`]: limitWidth && itemCount === 1,
    [`${containerClassName}--limit-width`]: limitWidth,
  });
  const btnGroup = isBtnGroup ? <CarouselButtonGroup /> : null;

  const [xDown, setXDown] = useState<null | number>(null);
  const [yDown, setYDown] = useState<null | number>(null);
  const bodyTag = useBodyRef();

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setXDown(event.touches[0].clientX);
    setYDown(event.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    setXDown(null);
    setYDown(null);
    if (bodyTag) {
      bodyTag.classList.remove(BODY_CLASS_NAME_MOBILE_MENU);
    }
  };

  const handleTouchMove = (evt: React.TouchEvent<HTMLDivElement>) => {
    if (!xDown || !yDown) {
      return;
    }
    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;
    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff) && bodyTag) {
      bodyTag.classList.add(BODY_CLASS_NAME_MOBILE_MENU);
    }
  };

  return (
    <div
      className={carouselClassName}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      <Carousel
        centerMode={centerMode}
        partialVisible={partialVissible}
        swipeable
        infinite={infinite}
        draggable={draggable || false}
        responsive={responsiveCarouselLayout}
        keyBoardControl
        containerClass={containerClass}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        itemClass="dt-carousel__item"
        arrows={false}
        customButtonGroup={btnGroup}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default ProductCarousel;
