import { ReactNode } from 'react';

export enum CarouselMasks {
  rightMask = 'right-mask',
  bothMasks = 'both-masks',
  bothMasksAssistant = 'both-masks-assistant',
  noMasks = 'no-masks',
  singleElement = 'single-element',
}

export interface IProductCarousel {
  children: ReactNode;
  type: CarouselMasks;
  centerMode?: boolean;
  draggable?: boolean;
  partialVissible?: boolean;
  infinite?: boolean;
  /** limit carousel width and center it when it has less than four elements */
  limitWidth?: boolean;
  visibleElements?: boolean;
  isBtnGroup?: boolean;
}
