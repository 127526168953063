import React, { FC } from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { ButtonGroupProps } from 'react-multi-carousel';

import './CarouselButtonGroup.scss';

const arrowClassName = 'react-multiple-carousel__arrow';

const CarouselButtonGroup: FC<ButtonGroupProps> = ({ next, previous, carouselState }) => {
  // @ts-ignore
  const { currentSlide, totalItems, slidesToShow } = carouselState;
  const isFirstSlide = currentSlide === 0;
  const isLastSlide = slidesToShow > totalItems || currentSlide === totalItems - slidesToShow;

  const backArrowStyle = classNames(arrowClassName, `${arrowClassName}--left`, {
    [`${arrowClassName}--disabled`]: isFirstSlide,
  });
  const nextArrowStyle = classNames(arrowClassName, `${arrowClassName}--right`, {
    [`${arrowClassName}--disabled`]: isLastSlide,
  });

  return (
    <div className="dt-carousel__button-group">
      <Button
        aria-label="Go to next slide"
        className={nextArrowStyle}
        disabled={isLastSlide}
        onClick={next}
      />
      <Button
        aria-label="Go to previous slide"
        className={backArrowStyle}
        disabled={isFirstSlide}
        onClick={previous}
      />
    </div>
  );
};

export default CarouselButtonGroup;
