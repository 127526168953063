import { CarouselMasks } from './models';

export default (itemCount: number) => ({
  [CarouselMasks.rightMask]: {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: Math.min(3, Math.max(2, itemCount)),
      partialVisibilityGutter: itemCount > 3 ? 40 : 0,
    },
    tablet: {
      breakpoint: { max: 991, min: 640 },
      items: 2,
      partialVisibilityGutter: itemCount > 2 ? 50 : 0,
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
      partialVisibilityGutter: itemCount > 2 ? 50 : 0,
    },
  },
  [CarouselMasks.singleElement]: {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
    },
  },
  [CarouselMasks.bothMasks]: {
    desktopLG: {
      breakpoint: { max: 4000, min: 1540 },
      items: Math.min(4, Math.max(2, itemCount)),
      partialVisibilityGutter: itemCount > 4 ? 50 : 0,
    },
    desktop: {
      breakpoint: { max: 1539, min: 992 },
      items: Math.min(3, Math.max(2, itemCount)),
      partialVisibilityGutter: itemCount > 3 ? 50 : 0,
    },
    tablet: {
      breakpoint: { max: 991, min: 640 },
      items: 2,
      partialVisibilityGutter: itemCount > 2 ? 50 : 0,
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
      partialVisibilityGutter: itemCount > 1 ? 50 : 0,
    },
  },
  [CarouselMasks.bothMasksAssistant]: {
    desktopXLG: {
      breakpoint: { max: 4000, min: 1540 },
      items: Math.min(5, Math.max(2, itemCount)),
      partialVisibilityGutter: itemCount > 5 ? 50 : 0,
    },
    desktopLG: {
      breakpoint: { max: 1539, min: 1220 },
      items: Math.min(4, Math.max(2, itemCount)),
      partialVisibilityGutter: itemCount > 4 ? 50 : 0,
    },
    desktop: {
      breakpoint: { max: 1219, min: 992 },
      items: Math.min(3, Math.max(2, itemCount)),
      partialVisibilityGutter: itemCount > 3 ? 50 : 0,
    },
    tablet: {
      breakpoint: { max: 991, min: 700 },
      items: 2,
      partialVisibilityGutter: itemCount > 2 ? 50 : 0,
    },
    mobile: {
      breakpoint: { max: 699, min: 0 },
      items: 1,
      partialVisibilityGutter: itemCount > 1 ? 50 : 0,
    },
  },
  [CarouselMasks.noMasks]: {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: Math.min(4, itemCount),
    },
    tablet: {
      breakpoint: { max: 991, min: 640 },
      items: Math.min(2, itemCount),
    },
    mobile: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
    },
  },
});
